import { Box, Center, Grid, GridItem, Image, Input, Stack, Table, TableContainer, Tbody, Td, Text, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { colors } from "../config/colors"
import axios from 'axios';

export function LandingPage (){

  const [dbResult, setDbResult] = useState()
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useLayoutEffect(() => {
    document.title = "Assistant GM"
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://assistantgm.glimpse-tangent.workers.dev/');
        setDbResult(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (!loading){
    console.log(error)
  }

  return (
    <>
    <Box >
      <Center>
        <Grid
          h='calc(100vh)'
          w='calc(60vw)'
          templateAreas={`
          "header topnav"
          "maintop maintop"
          "mainleft mainright"
          "mainbottom mainbottom"
          "footer footer"`}
          gridTemplateRows={'50px 1fr 1fr 1fr 50px'}
          gridTemplateColumns={'1fr 1fr'}
          gap='0'
        >
          <GridItem area={"header"}  >
            <Stack direction={"horizontal"} minHeight={"100%"}>
              <Image src="logo.png" maxHeight={'48px'}></Image>
              <Text color={colors.LOGOWHITE} fontWeight={1000} fontSize={'30px'} >Assistant GM</Text>
            </Stack>
          </GridItem>

          <GridItem area={'topnav'} >
            <Box display={'flex'} justifyContent="flex-end" paddingRight={'10px'}>
              <Stack direction={"horizontal"} minHeight={"100%"} spacing={5}>
                  <Link style={{color: colors.WHITE, fontWeight: 600, fontSize: '18px'}} href="#" >LEAGUE</Link>
                  <Link style={{color: colors.WHITE, fontWeight: 600, fontSize: '18px'}} href="#">TEAMS</Link>
                  <Link style={{color: colors.WHITE, fontWeight: 600, fontSize: '18px'}} href="#">PLAYERS</Link>
                  <Link style={{color: colors.WHITE, fontWeight: 600, fontSize: '18px'}} href="#">ABOUT</Link>
                </Stack>
            </Box>
          </GridItem>

          <GridItem area={"maintop"}>
            <Stack>
              <Text color={colors.WHITE}>
              NHL salary cap analysis and player performance tracking
              </Text>
              <Center>
              <Input color={colors.WHITE} w="25%" placeholder="Search for a player or team"></Input>
              </Center>
            </Stack>

          </GridItem>

          <GridItem area={"mainleft"}>
          <Stack>
              <Text color={colors.TEAL}>
              LEAGUE VIEW
              </Text>

              <Text color={colors.TEAL}>
              PLAYER PERFORMANCE ANALYTICS
              </Text>

              <Text color={colors.TEAL}>
              Beyond advanced stats. Complete your player performance analysis with our analytics.
              </Text>
            </Stack>
          </GridItem>

          <GridItem area={"mainright"}>
          <Stack>
              <Text color={colors.TEAL}>
              PLAYER VIEW
              </Text>

              <TableContainer overflowY={"auto"} position="relative" maxHeight={300}>
                <Table size='sm' colorScheme={colors.TEAL} >
                  <Thead position="sticky" top={0} backgroundColor="#294149" color="7ca99d" borderTop="none">
                    <Tr>
                      <Td color="#b6e5d9">First name</Td>
                      <Td color="#7ca99d">Last Name</Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                  {dbResult && dbResult.map((row) => (
                    <Tr key={row.id}>
                      <Td color="#b6e5d9">{row.first_name["default"]}</Td>
                      <Td color="#b6e5d9">{row.last_name["default"]}</Td>
                    </Tr>
                  ))}
                  </Tbody>
                </Table>
              </TableContainer>
              

              <Text color={colors.TEAL}>
              PLAYER CAP VALUE ANALYTICS
              </Text>

              <Text color={colors.TEAL}>
              Discover which players provide the best value based on their performance vs salary cap hit.
              </Text>
            </Stack>
          </GridItem>

          <GridItem area={"mainbottom"}>
            <Stack>
              <Text color={colors.TEAL}>
                RECENT PLAYER TRANSACTIONS
              </Text>
              <Text color={colors.TEAL}>
                LATEST NHL NEWS
              </Text>
            </Stack>
          </GridItem>

          <GridItem area={"footer"} w="100%">
            <Text backgroundColor='#222222' color={colors.WHITE}>
            © 2024 Assistant GM. All rights reserved. 
            </Text>
          </GridItem>
        </Grid>
      </Center>
      
    </Box>
    
    </>
  )
}